import React from "react"
import EventDetails from "../../components/eventdetails"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { eventData } from "../../_mockdata/eventsData"
const event = eventData[3]
const GCPStudentCluster = () => (
  <Layout>
    <SEO title="GCP Cluster Competition" description="" lang="en" meta={[]} />
    <EventDetails
      image={event.image}
      type={event.type}
      title={event.title}
      publishedDate={event.publishedDate}
      internalLink={event.internalLink!}
      eventDetails={event.eventDetails}
      id={event.id}
      eventDate={event.eventDate}
      location={event.location}
    />
  </Layout>
)

export default GCPStudentCluster
